import React, { useState, useEffect, useCallback } from 'react';
import './Countdown.css';

const Countdown = ({ targetDate }) => {
    const calculateTimeLeft = useCallback(() => {
        const difference = new Date(targetDate) - new Date();
        if (difference > 0) {
            return {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }
        return {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        };
    }, [targetDate]);

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, [calculateTimeLeft]);

    return (
        <div className="countdown">
            <div className="countdown-item">
                <span>{timeLeft.days.toString().padStart(2, '0')}</span> <small>Days</small>
            </div>
            <div className="countdown-item">
                <span>{timeLeft.hours.toString().padStart(2, '0')}</span> <small>Hours</small>
            </div>
            <div className="countdown-item">
                <span>{timeLeft.minutes.toString().padStart(2, '0')}</span> <small>Minutes</small>
            </div>
            <div className="countdown-item">
                <span>{timeLeft.seconds.toString().padStart(2, '0')}</span> <small>Seconds</small>
            </div>
        </div>
    );
};

export default Countdown;
